var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('BCard',{staticClass:"p-[11px]"},[_c('div',{staticClass:"text-black text-2xl font-semibold mb-1"},[_vm._v(" Ubah Trainer ")]),_c('b-overlay',{attrs:{"variant":"light","show":_vm.loading,"spinner-variant":"primary","blur":"0","opacity":".5","rounded":"sm"}},[_c('validation-observer',{ref:"formRules",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('b-row',[_c('b-col',{attrs:{"md":"8"}},[_c('b-form',[_c('b-row',[_c('b-col',{attrs:{"md":"12"}},[_c('b-form-group',{attrs:{"label":"Nama lengkap","label-cols-md":"4","label-class":"font-medium text-7 text-black"}},[_c('validation-provider',{attrs:{"name":"Nama Lengkap","rules":"required|min:3|max:50|alpha_spaces"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"state":errors.length > 0 || _vm.submitErrors.name ? false : null,"formatter":_vm.capitalize},model:{value:(_vm.name),callback:function ($$v) {_vm.name=$$v},expression:"name"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0] || _vm.submitErrors.name))])]}}],null,true)})],1)],1),_c('b-col',{attrs:{"md":"12"}},[_c('b-form-group',{attrs:{"label":"Skill Role","label-cols-md":"4","label-class":"font-medium text-7 text-black"}},[_c('validation-provider',{attrs:{"name":"Skill Role","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{staticClass:"w-100",attrs:{"placeholder":"Pilih Skill Role","label":'name',"clearable":true,"options":_vm.listSkills,"reduce":function (skill) { return skill.id; }},model:{value:(_vm.skillId),callback:function ($$v) {_vm.skillId=$$v},expression:"skillId"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1),_c('b-col',{attrs:{"md":"12"}},[_c('b-form-group',{attrs:{"label":"No hp","label-cols-md":"4","label-class":"font-medium text-7 text-black"}},[_c('validation-provider',{attrs:{"name":"No Hp","rules":"required|min:9|max:15"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('cleave',{staticClass:"form-control",attrs:{"options":_vm.options.phone},model:{value:(_vm.phone),callback:function ($$v) {_vm.phone=$$v},expression:"phone"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1),_c('b-col',{attrs:{"md":"12"}},[_c('b-form-group',{attrs:{"label":"Lokasi","label-cols-md":"4","label-class":"font-medium text-7 text-black"}},[_c('validation-provider',{attrs:{"name":"Lokasi","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"reduce":function (option) { return option.subdistrict_id; },"options":_vm.listSubdistrict,"placeholder":"Ketik untuk mencari..."},on:{"search":function (search) { return search.length > 0 ? _vm.onSearchSubdistrict(search) : ''; }},scopedSlots:_vm._u([{key:"selected-option-container",fn:function(ref){
var option = ref.option;
return [_c('div',{staticClass:"vs__selected"},[_vm._v(" "+_vm._s(((option.subdistrict_name) + ", " + (option.district_name) + ", " + (option.regency_name) + ", " + (option.province_name)))+" ")])]}}],null,true),model:{value:(_vm.subdistrictId),callback:function ($$v) {_vm.subdistrictId=$$v},expression:"subdistrictId"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1),_c('b-col',{attrs:{"md":"12"}},[_c('b-form-group',{attrs:{"label":"Detail Alamat","label-cols-md":"4","label-class":"font-medium text-7 text-black"}},[_c('validation-provider',{attrs:{"name":"Detail Alamat","rules":"required|min:5"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-textarea',{attrs:{"state":errors.length > 0 || _vm.submitErrors.address ? false : null},model:{value:(_vm.address),callback:function ($$v) {_vm.address=$$v},expression:"address"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0] || _vm.submitErrors.address))])]}}],null,true)})],1)],1),_c('b-col',{attrs:{"md":"12"}},[_c('b-form-group',{attrs:{"label":"Nama Bank","label-cols-md":"4","label-class":"font-medium text-7 text-black"}},[_c('validation-provider',{attrs:{"name":"Nama Bank","rules":'required'},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"label":"name","reduce":function (option) { return option.code; },"options":_vm.listBank,"filterable":true,"state":errors.length > 0 ? false : null,"placeholder":"Ketik untuk mencari..."},model:{value:(_vm.bankCode),callback:function ($$v) {_vm.bankCode=$$v},expression:"bankCode"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1),_c('b-col',{attrs:{"md":"12"}},[_c('b-form-group',{attrs:{"label":"No Rekening","label-cols-md":"4","label-class":"font-medium text-7 text-black"}},[_c('validation-provider',{attrs:{"name":"No rekening","rules":'required|numeric'},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"type":"text","state":errors.length > 0 ? false : null},model:{value:(_vm.bankNo),callback:function ($$v) {_vm.bankNo=$$v},expression:"bankNo"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1),_c('b-col',{attrs:{"md":"12"}},[_c('b-form-group',{attrs:{"label":"Nama Pemilik Rekening","label-cols-md":"4","label-class":"font-medium text-7 text-black"}},[_c('validation-provider',{attrs:{"name":"Nama pemilik rekening","rules":"required|alpha_spaces"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"type":"text","formatter":_vm.capitalize,"state":errors.length > 0 ? false : null},model:{value:(_vm.bankOwnerName),callback:function ($$v) {_vm.bankOwnerName=$$v},expression:"bankOwnerName"}}),_c('small',{staticClass:"text-danger d-block"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1),_c('b-col',{attrs:{"md":"12"}},[_c('b-form-group',{attrs:{"label":"NPWP","label-cols-md":"4","label-class":"font-medium text-7 text-black"}},[_c('validation-provider',{attrs:{"name":"NPWP","rules":'numeric'},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"type":"text","state":errors.length > 0 ? false : null},model:{value:(_vm.npwp),callback:function ($$v) {_vm.npwp=$$v},expression:"npwp"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1),_c('b-col',{attrs:{"md":"12"}},[_c('b-form-group',{attrs:{"label":"CV","label-cols-md":"4","label-class":"font-medium text-7 text-black"}},[_c('div',{staticClass:"d-flex gap-10"},[_c('div',{staticClass:"w-100"},[_c('validation-provider',{attrs:{"name":"CV","rules":"ext:pdf"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-file',{staticClass:"w-100",attrs:{"state":errors.length > 0 ? false : null,"placeholder":_vm.initialFile
                              ? _vm.initialFile.split('/').pop()
                              : "Pilih atau drop file disini","drop-placeholder":"Pilih atau drop file disini","accept":".pdf, .xls, .xlsx"},on:{"change":function($event){return _vm.uploadFile($event)}},model:{value:(_vm.fileSelected),callback:function ($$v) {_vm.fileSelected=$$v},expression:"fileSelected"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),(_vm.fileSelected)?_c('div',[_c('BButton',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover.top",value:('Lihat File'),expression:"'Lihat File'",modifiers:{"hover":true,"top":true}}],staticClass:"ml-auto",attrs:{"variant":"outline-primary","target":"_blank","href":_vm.fileSelected ? _vm.fileUrl(_vm.fileSelected) : _vm.attachmentInitialFile}},[_vm._v(" Preview ")])],1):_vm._e()])])],1)],1)],1)],1)],1),_c('div',{staticClass:"d-flex justify-content-end mt-3"},[_c('button',{staticClass:"mr-50 button-secondary",attrs:{"type":"kembali"},on:{"click":function($event){$event.preventDefault();return _vm.$router.back()}}},[_vm._v(" Kembali ")]),_c('button',{staticClass:"mr-50 button-primary",attrs:{"disabled":invalid || _vm.loadingSubmit},on:{"click":function($event){$event.preventDefault();return _vm.submit()}}},[(_vm.loadingSubmit)?_c('b-spinner',{attrs:{"small":""}}):_vm._e(),_vm._v(" "+_vm._s(_vm.editAction ? 'Simpan' : 'Simpan & Lanjutkan')+" ")],1)])]}}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }